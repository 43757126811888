import { StrapiFooter } from '@/api/footer';
import { StrapiMenu } from '@/api/menu';
import { Footer } from '@/components/footer';
import { Menu } from '@/components/menu';
import { SectionWithCenteredMessage } from '@/components/section-with-centered-message';
import React from 'react';


export default function Custom404Page({
  pageContext: {
    strapiMenu,
    strapiFooter,
  },
}: {
  pageContext: {
    strapiMenu: StrapiMenu;
    strapiFooter: StrapiFooter;
  };
}) {
  return (
    <>
      <Menu strapiMenu={strapiMenu} />
      <SectionWithCenteredMessage
        title={'We Lost This Page'}
        message={
          'We searched high and low but couldn\'t find what you\'re looking for. Let\'s find a better place for you to go.'
        }
      />
      <Footer strapiFooter={strapiFooter} />
    </>
  );
}
