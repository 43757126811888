import React, { FC } from 'react';
import { Container, Typography } from '@mui/material';
import { MainColumn } from '@/components/main-column';
import { navigate } from 'gatsby';
import { TrackedButton } from '../tracked-button';
import { MENU_HEIGHT } from '@/constants';

export type ISectionWithCenteredMessage = {
  title: string;
  message: string;
};
export const SectionWithCenteredMessage: FC<ISectionWithCenteredMessage> = ({
  title,
  message,
}) => (
  <Container
    maxWidth={false}
    disableGutters
    sx={{
      display: 'flex',
      alignItems: 'center',
      mt: `${MENU_HEIGHT}px`,
      backgroundColor: 'greyscale.cultured',
    }}
  >
    <MainColumn
      sx={{
        textAlign: 'center',
        mx: 'auto',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: { md: '900px', sm: '600px', xs: '400px' },
      }}
    >
      <Typography mx={'auto'} maxWidth={'720px'} variant={'h2'}>
        {title}
      </Typography>
      <Typography mx={'auto'} py={3} maxWidth={'720px'} variant={'body1'}>
        {message}
      </Typography>
      <TrackedButton
        variant={'contained'}
        type={'submit'}
        onClick={() => navigate('/')}
      >
        Back Home
      </TrackedButton>
    </MainColumn>
  </Container>
);
